









import { Component, Vue } from 'vue-property-decorator'
import Drawer from '@/layouts/components/Drawer.vue'
import { namespace } from 'vuex-class'
const UserStore = namespace('UserStore')

@Component({
  components: { Drawer },
})
export default class ProfileLayout extends Vue {
  @UserStore.Getter
  public isAuth!: boolean

  // public mounted(): void {
  //   if (!this.isAuth) {
  //     this.$router.push({ name: 'Login' })
  //   }
  // }
}
